* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
  font-family: "Mulish";
}

iframe {
  pointer-events: none;
}

img {
  width: 100%;
}

textarea,
select,
input {
  border: none;
  outline: none;
}

input,
textarea {
  -webkit-appearance: none;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input[type="date"]:before {
  color: rgba(33, 51, 79, 0.8);
}

input[type="date"]:after {
  color: #21334f;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-font {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
}
.form-font::placeholder {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 20px;
  color: rgba(33, 51, 79, 0.8);
}

.form-label {
  display: block;
  color: #21334f;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1.2rem;
}

.form-error {
  color: #ef5350;
  font-size: 1.2rem;
  display: block;
  max-width: 310px;
}

.cancel-delete {
  color: white;
  text-decoration: none;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  width: 8.1rem;
  height: 3.2rem;
  font-weight: 400;
  font-size: 16px;
  border: none;
  text-align: center;
  background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
  white-space: nowrap;
  border: none;
}

.swal-button {
  color: white;
  text-decoration: none;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  width: 8.1rem;
  height: 3.2rem;
  font-weight: 400;
  font-size: 16px;
  border: none;
  text-align: center;
  white-space: nowrap;
  border: none;
}

.confirm--delete {
  background-color: #ef5350 !important;
}
.form-btn {
  border: none;
}
.form-btn:hover,
.form-btn:focus {
  background: #34a853;
}

.bg__white {
  background-color: #fff !important;
}

.black-text {
  color: #21334f;
}

.green-text {
  color: #34a853;
}

.red-text {
  color: #f04438;
}

.white_text {
  color: #fff;
}

.wi-20 {
  width: 20px;
}

.wi-40 {
  width: 40px;
}

.rounded-6 {
  border-radius: 6px;
}

body {
  margin: 0;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fs-4 {
  font-weight: 600;
  font-size: 4rem;
  line-height: 6rem;
}
fw-6 {
  font-family: "Mulish";
  font-weight: 600;
}
fw-4 {
  font-family: "Mulish";
  font-weight: 400;
}

img {
  width: 100%;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  height: auto;
}

.spinner {
  margin: 0 auto;
}
.btn-comp:hover,
.btn-comp:focus {
  background: #34a853;
  color: white;
  text-decoration: none;
}
.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(0px), translateY(-100px);
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(0px), translateY(-100px);
}

.dialog-sheet{
  background-color: #fff;
  border: 1px solid #000;
  z-index: 99;
}

#nprogress .bar {
  background: #34a853!important;
  height: 5px !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #34a853, 0 0 5px #34a853 !important;
}

#nprogress .spinner-icon {
  border-top-color: #34a853 !important;
  border-left-color: #34a853 !important;
}
